import React, { useState, useEffect } from 'react';
import GatsbyImage from 'gatsby-image';
import PropTypes from 'prop-types';
import SEO from '../components/meta/seo';
import filtersVideo from '../assets/video/showcase/filters.mp4';
import backgroundRemovalVideo from '../assets/video/showcase/backgroundRemoval.mp4';
import blendingVideo from '../assets/video/showcase/blendingMode.mp4';
import blurVideo from '../assets/video/showcase/blur.mp4';
import extractAudioVideo from '../assets/video/showcase/extractAudio.mp4';
import lumaKeyTransitionsVideo from '../assets/video/showcase/lumaKeyTransitions.mp4';
import newEffectsVideo from '../assets/video/showcase/newEffects.mp4';
import stickersVideo from '../assets/video/showcase/stickers.mp4';
import { graphql, useStaticQuery } from 'gatsby';
import '../styles/_nocookie-policy.css';
import { parse } from 'query-string';

const SpliceFeatureShowcase = ({ location }) => {
  const [featureInfo, setFeatureInfo] = useState({});

  const { filtersIcon, backgroundRemovalIcon, blendingIcon, blurIcon, extractAudioIcon, newEffectsIcon, stickersIcon, transitionIcon } =
    useStaticQuery(graphql`
      {
        filtersIcon: file(relativePath: { eq: "premium-filters.png" }) {
          childImageSharp {
            fluid(maxWidth: 24, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        backgroundRemovalIcon: file(relativePath: { eq: "backgroundRemoval.png" }) {
          childImageSharp {
            fluid(maxWidth: 24, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        blendingIcon: file(relativePath: { eq: "blend-modes.png" }) {
          childImageSharp {
            fluid(maxWidth: 24, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        blurIcon: file(relativePath: { eq: "blur.png" }) {
          childImageSharp {
            fluid(maxWidth: 24, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        extractAudioIcon: file(relativePath: { eq: "extract-from-video.png" }) {
          childImageSharp {
            fluid(maxWidth: 24, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        transitionIcon: file(relativePath: { eq: "transition.png" }) {
          childImageSharp {
            fluid(maxWidth: 24, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        newEffectsIcon: file(relativePath: { eq: "newEffects.png" }) {
          childImageSharp {
            fluid(maxWidth: 24, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        stickersIcon: file(relativePath: { eq: "stickers.png" }) {
          childImageSharp {
            fluid(maxWidth: 24, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `);

  useEffect(() => {
    // Override body background to prevent white-black flashes when opening the webview and loading the DOM content
    document.body.className = 'section-dark';
    var info = {};

    // Consolidate the right info and assets based on the query string
    const queryParams = parse(location.search);
    switch (queryParams.feature) {
      case 'cinematic-filters':
        info = {
          videoSource: filtersVideo,
          icon: filtersIcon,
          heading: 'Cinematic filters',
          title: 'The right look in seconds.',
          body: 'With 20+ new exclusive filters, you can make your videos look like some of the most famous movies.',
        };
        break;
      case 'background-removal':
        info = {
          videoSource: backgroundRemovalVideo,
          icon: backgroundRemovalIcon,
          heading: 'Background removal',
          title: 'Effortless Background Removal with AI Magic.',
          body: 'Transport your videos to faraway places in just one tap.',
        };
        break;
      case 'blending-modes':
        info = {
          videoSource: blendingVideo,
          icon: blendingIcon,
          heading: 'Blending modes',
          title: 'Mix and match layers to make your videos pop.',
          body: 'Experiment with blending modes and discover new ways to combine colors and textures in your videos.',
        };
        break;
      case 'blur':
        info = {
          videoSource: blurVideo,
          icon: blurIcon,
          heading: 'Blur',
          title: 'Quickly blur parts of your video.',
          body: 'Great for creating interesting visual effects or for blurring out sensitive information. ',
        };
        break;
        case 'lumaKey-transition':
          info = {
            videoSource: lumaKeyTransitionsVideo,
            icon: transitionIcon,
            heading: 'Luma Key transitions',
            title: 'Master the art of visual fusion.',
            body: 'Merge images and videos effortlessly, creating captivating compositions that leave a lasting impression.',
          };
          break;
      case 'new-effects':
        info = {
          videoSource: newEffectsVideo,
          icon: newEffectsIcon,
          heading: 'New video effects',
          title: 'Elevate your creativity with 20+ dazzling new video effects.',
          body: 'Try out effects such as Shake or Ripple to give a new flavour to your video.',
        };
        break;
      case 'stickers':
        info = {
          videoSource: stickersVideo,
          icon: stickersIcon,
          heading: 'Stickers powered by Giphy',
          title: 'New animated stickers, text and gifs for you to try out.',
          body: 'Add a fun, expressive touch to your videos with this freshly added library.',
        };
        break;
      default:
        info = {
          videoSource: extractAudioVideo,
          icon: extractAudioIcon,
          heading: 'Extract audio from video',
          title: 'Turn your videos into audio files.',
          body: 'Extract music, sound effects, or just your voice from any video and use them in your projects.',
        };
        break;
    }
    setFeatureInfo(info);
  }, [location.search, setFeatureInfo]);

  return (
    <div className="dark section-dark min-h-screen flex flex-col relative items-center">
      <SEO
        title="What's new in Splice"
        meta={[{ name: 'robots', value: 'noindex' }]}
      />
      <div className="SB16 text-center text-white p-1 select-none">
        What's new in Splice
      </div>
      <div className="flex-1 flex items-center justify-center w-full">
        <main
          className="card overflow-hidden p-0 mx-5 flex flex-col rounded-xl"
          style={{
            backgroundImage: 'linear-gradient(to bottom, #252A30, #16181A)',
            maxWidth: '300px',
          }}
        >
          <div
            className="relative w-full"
            style={{
              height: '200px',
            }}
          >
            <div className="absolute inset-0 w-full h-full">
              <video
                className="w-full h-full object-cover"
                muted
                autoPlay
                loop
                playsInline
                key={featureInfo.videoSource}
              >
                <source src={featureInfo.videoSource} type="video/mp4" />
              </video>
            </div>
          </div>
          <div
            className="text-center items-center flex flex-col leading-tight tracking-tight select-none"
            style={{
              padding: '20px',
            }}
          >
            <div
              className="SB13 text-black leading-normal px-1 py-0 rounded"
              style={{
                backgroundColor: '#FFD260',
                fontSize: '9px',
              }}
            >
              New
            </div>
            <div className="SB15 flex text-center flex-row mt-3 items-center">
              {featureInfo.icon && (
                <GatsbyImage
                  fluid={featureInfo.icon.childImageSharp.fluid}
                  alt="Feature icon"
                  style={{
                    width: '24px',
                    height: '24px',
                  }}
                />
              )}
              <div className="text-accent-main ml-1">{featureInfo.heading}</div>
            </div>
            <div
              className="SB20 mt-3"
              style={{
                fontSize: '22px',
              }}
            >
              {featureInfo.title}
            </div>
            <div
              className="M15 opacity-70 mt-3"
              style={{
                lineHeight: '115%',
                fontWeight: 'normal',
              }}
            >
              {featureInfo.body}
            </div>
            <button
              className="SB16 block btn btn--blue py-4 px-8 drop-shadow-none rounded-lg text-center w-full"
              style={{
                marginTop: '32px',
              }}
              onClick={() => {
                if (
                  window &&
                  window.webkit &&
                  window.webkit.messageHandlers &&
                  window.webkit.messageHandlers.handleWebViewDismissal
                )
                  window.webkit.messageHandlers.handleWebViewDismissal.postMessage(
                    {}
                  );
              }}
            >
              Got it!
            </button>
          </div>
        </main>
      </div>
    </div>
  );
};

SpliceFeatureShowcase.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

export default SpliceFeatureShowcase;
